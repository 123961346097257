import { useEffect, useState, useRef } from "react";
import queryString from "query-string";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import { prepareResponse, submitPreSurvey, updateRespondentDevice } from "../../api/Project";

import Loader from "../../themes/components/Loader";
import { notify, notifySimple } from "../../themes/components/Notifications";
import PreSurvey from "../../components/Suppliers/PreSurvey";

const KEY = "obsrvnow-response";

const ResponsePrepareRoute: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true),
    [questions, setQuestions] = useState<ProjectSupplierQuestion[]>([]),
    [respondentId, setRespondentId] = useState<string>(""),
    invokeApiRef = useRef(null);

  const urlParams = queryString.parse(document.location.search);

  const projectId = typeof urlParams["obsrv_pid"] === "string" ? urlParams["obsrv_pid"] : "";
  const project_supplier_id =
    typeof urlParams["obsrv_sid"] === "string" ? urlParams["obsrv_sid"] : "";

  const evaluateDevice = async (
    respondentId: string,
    projectSupplierId: string
  ): Promise<JsonObject | null> => {
    if (!invokeApiRef.current) {
      console.warn("Forensiq script is not yet initialized");
      return null;
    }
    try {
      const result: JsonObject | null = await invokeApiRef.current(respondentId, projectSupplierId);
      return result;
    } catch (error) {
      return null;
    }
  };

  const onPrepareResponse = async () => {
    try {
      const response = await prepareResponse({
        project_id: projectId,
        project_supplier_id: project_supplier_id,
        urlParams: JSON.stringify(omit(urlParams, ["obsrv_sid", "obsrv_pid"])),
      });
      const { result, messages } = response.responsePrepare;

      if (!isEmpty(messages)) {
        notify(messages, "error");
      } else if (result) {
        sessionStorage.setItem(
          KEY,
          JSON.stringify({
            id: result.id,
            respondent_key: result.respondent_key,
            status_key: result.status_key,
          })
        );

        const evaluated = await evaluateDevice(result.id, project_supplier_id);

        if (evaluated) {
          await updateRespondentDevice(result.id, evaluated);
        }

        if (result.should_screen && result.questions) {
          sessionStorage.setItem("redirect_url", result.redirect_url);
          setRespondentId(result.id);
          setQuestions(result.questions);
          setLoading(false);
        } else {
          window.location.href = result.redirect_url;
        }
      }
    } catch (err) {
      notifySimple(["Unexpected error occurred"], "error");
    }
  };

  const submitAnswers = (answers: PreSurveyAnswer[]): void => {
    try {
      submitPreSurvey(project_supplier_id, answers, respondentId)
        .then((response) => {
          const { result, messages } = response.ProjectSupplierPreSurveySubmit;

          if (!isEmpty(messages)) {
            notify(messages, "error");
          } else {
            if (result) {
              if (result.status === "terminated" && result.redirect_url) {
                window.location.href = result.redirect_url;
                return;
              }
              const redirectUrl = sessionStorage.getItem("redirect_url");
              if (redirectUrl && typeof redirectUrl === "string") {
                window.location.href = redirectUrl;
                return;
              }
              notifySimple(["Unexpected error occurred"], "error");
            }
            notifySimple(["You cannot proceed to the survey"], "error");
          }
        })
        .catch(() => {
          notifySimple(["You cannot proceed to the survey"], "error");
        });
    } catch (err) {
      notifySimple(["Unexpected error occurred"], "error");
    }
  };

  useEffect(() => {
    const key: string = import.meta.env.VITE_APP_DEVICE_FORENSIQ_KEY || null,
      channel: string = import.meta.env.VITE_APP_DEVICE_FORENSIQ_CHANNEL || null;
    if (!key || !channel) return;
    const script = document.createElement("script");
    script.src = "https://api-cdn.dfiq.net/scripts/forensic-v5.6.1.min.js";
    script.id = "forensic_script_id";
    script.type = "text/javascript";
    script.setAttribute("data-key", key);
    script.setAttribute("data-ur", "");
    script.setAttribute("data-uax", "");
    script.onload = () => {
      invokeApiRef.current = (
        respondentId: string,
        projectSupplierId: string
      ): Promise<JsonObject | null> => {
        return new Promise((resolve, reject) => {
          const uniquenessParam = window.Forensic.createUniquenessParam(projectSupplierId),
            userLocale = Intl.DateTimeFormat().resolvedOptions().locale,
            geoParam = window.Forensic.createGeoParam(userLocale.split("-")[1] || null);
          const successCallback = (jsonData: JsonObject) => {
            if (
              jsonData.forensic.marker.score < 25 &&
              jsonData.forensic.unique.isEventUnique === true
            ) {
              // Handle non-fraud/unique case
            } else {
              // Handle fraud/unique case
            }
            resolve(jsonData);
          };
          const errorCallback = (jsonData: JsonObject) => {
            console.error(jsonData.error.message);
            reject(null);
          };
          window.Forensic.forensic(
            respondentId,
            successCallback,
            errorCallback,
            uniquenessParam,
            geoParam,
            null,
            null,
            channel
          );
        });
      };
      void onPrepareResponse();
    };
    script.onerror = () => {
      void onPrepareResponse();
      console.error("Forensiq script failed to load");
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (loading) {
    return <Loader />;
  }

  return <PreSurvey questions={questions} submitAnswers={submitAnswers} />;
};

export default ResponsePrepareRoute;
